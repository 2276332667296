import Pulpa1 from "../assets/assetslagranja/Blog/Lagranja-uva.jpg";
import Pulpa2 from "../assets/assetslagranja/Blog/Lagranja-maracuya-3.jpg";
import Pulpa3 from "../assets/assetslagranja/Blog/Lagranja-fresa.JPG";
import Pulpa4 from "../assets/assetslagranja/Blog/Lagranja-mango.jpg";
import Pulpa5 from "../assets/assetslagranja/Blog/Lagranja-azai.jpg";
import Pulpa6 from "../assets/assetslagranja/Blog/Lagranja-piña.jpg";


export const ProductList = [
  {
    name: "Pulpa de Uva",
    image: Pulpa1,
    price: "250 gr",
  },
  {
    name: "Pulpa de Maracuyá",
    image: Pulpa2,
    price: "250 gr",
  },
  {
    name: "Pulpa de Fresa",
    image: Pulpa3,
    price: "250 gr",
  },
  {
    name: "Pulpa de Mango",
    image: Pulpa4,
    price: "250 gr",
  },
  {
    name: "Pulpa de Azaí",
    image: Pulpa5,
    price:"1 Kilo",
  },
  {
    name: "Pulpa de Piña",
    image: Pulpa6,
    price: "250 gr",
  },
];