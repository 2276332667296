import React from "react";
import "../styles/normalize.css";
import "../styles/Inicio.css";
import { Link } from "react-router-dom";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import slide1 from '../assets/assetslagranja/Farm-min_opt.webp'
import slide1_mob from '../assets/assetslagranja/Farm-mobile.webp'
import slide2 from '../assets/assetslagranja/Fresa_1280_opt.jpg'

import clientSlide1 from '../assets/assetslagranja/Captura_La_Doctora.jpg'
import clientSlide2 from '../assets/assetslagranja/Captura_La_Rufina.jpg'
import clientSlide3 from '../assets/assetslagranja/cliente-cuzco-350.jpeg'
import clientSlide4 from '../assets/assetslagranja/micro_snip-350x153-modified.png'


function Inicio() {
  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 1280 },
      items: 1,
      // slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1279, min: 600 },
      items: 1,
      //  slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      //  slidesToSlide: 1 // optional, default to 1.
    }
  }
  const responsiveClient = {
    desktop: {
      breakpoint: { max: 4000, min: 1280 },
      items: 4,
      // slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1279, min: 601 },
      items: 3,
      //  slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 2,
      //  slidesToSlide: 1 // optional, default to 1.
    }
  }
    ;


  return (
    <section className="slider-carousel">
      <Carousel
        responsive={responsive}
        className="home-carousel"
        swipeable={true}
        //draggable={false}
        showDots={true}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={2500}
        keyBoardControl={true}
        //customTransition="all .5"
        transitionDuration={20000}

      >
        <div className='container--card'>
          <img className='container__image--product'
            src={slide1}
            srcSet={`${slide1_mob} 600w, ${slide1} 1280w`}

            alt="Granja" />

        </div>
        <div className='container--card'>
          <img className='container__image--product'
            src={slide2} alt="Fresas" />
        </div>

      </Carousel>

      <div className="container--slogan">
        <h1 className="container__header--title-slogan"><span className="container__span--texto-verde">Pulpas 100% Naturales...</span><pre> </pre>Sin colorantes, saborizantes artificiales y sin conservantes </h1>
      </div>
      <div className="container--button-section">
        <Link to="/productos"><button className="container__button--nuestros-productos">Nuestros Productos</button></Link>
        <Link to="/sobreNosotros"><button className="container__button--conocenos">Conócenos</button></Link>
        <Link to="/contactanos"><button className="container__button--contacto"> Contacto</button>  </Link>
        <Link to="/encuentranos"><button className="container__button--ubicacion">Ubicación</button> </Link>
      </div>
      <div className="container--clientes">
        <h2 className="container__header--title-clientes"> Nuestros clientes </h2>
        <div className="container--client-carousel">
          <Carousel responsive={responsiveClient}
            className="client-carousel"
            swipeable={false}
            draggable={false}
            showDots={false}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3500}
            keyBoardControl={false}
            //customTransition="all 5"
            //transitionTime={1}
            //duration = {500}
            infiniteLoop={true}
          >
            <div className='container__card-client'>
              <img className='container__image--client'
                src={clientSlide1}
                alt="Logo de cliente" />
            </div>
            <div className='container__card-client'>
              <img className='container__image--client'
                src={clientSlide2} alt="Logo de cliente" />
            </div>
            <div className='container__card-client'>
              <img className='container__image--client'
                src={clientSlide3} alt="Logo de cliente" />
            </div>
            <div className='container__card-client'>
              <img className='container__image--client'
                src={clientSlide4} alt="Logo de cliente" />
            </div>
          </Carousel>
        </div>
      </div>


      <div className="container--porque-iconos">
        <h2 className="container__header--porque-elegirnos"> ¿Por qué elegirnos? </h2>

        <div className="container--icons-parent">

        <div className="container--icons">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-6 0 35 24" strokeWidth="1.5" stroke="rgb(127, 96, 0)" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12" />
            </svg>
            <h3>Cumplimos normas sanitarias</h3>
          </div>


          <div className="container--icons">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-12 0 35 24" strokeWidth="1.5" stroke="rgb(127, 96, 0)" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6.115 5.19l.319 1.913A6 6 0 008.11 10.36L9.75 12l-.387.775c-.217.433-.132.956.21 1.298l1.348 1.348c.21.21.329.497.329.795v1.089c0 .426.24.815.622 1.006l.153.076c.433.217.956.132 1.298-.21l.723-.723a8.7 8.7 0 002.288-4.042 1.087 1.087 0 00-.358-1.099l-1.33-1.108c-.251-.21-.582-.299-.905-.245l-1.17.195a1.125 1.125 0 01-.98-.314l-.295-.295a1.125 1.125 0 010-1.591l.13-.132a1.125 1.125 0 011.3-.21l.603.302a.809.809 0 001.086-1.086L14.25 7.5l1.256-.837a4.5 4.5 0 001.528-1.732l.146-.292M6.115 5.19A9 9 0 1017.18 4.64M6.115 5.19A8.965 8.965 0 0112 3c1.929 0 3.716.607 5.18 1.64" />
            </svg>
            <h3>Productos naturales</h3>
          </div>

          <div className="container--icons">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-12 0 35 24" strokeWidth="1.5" stroke="rgb(127, 96, 0)" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <h3>10+ años en el mercado</h3>
          </div>




          <div className="container--icons">
            <svg xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="50 0 550 512">  {/*Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. */}<path d="M96 64c0-35.3 28.7-64 64-64H266.3c26.2 0 49.7 15.9 59.4 40.2L373.7 160H480V126.2c0-24.8 5.8-49.3 16.9-71.6l2.5-5c7.9-15.8 27.1-22.2 42.9-14.3s22.2 27.1 14.3 42.9l-2.5 5c-6.7 13.3-10.1 28-10.1 42.9V160h56c22.1 0 40 17.9 40 40v45.4c0 16.5-8.5 31.9-22.6 40.7l-43.3 27.1c-14.2-5.9-29.8-9.2-46.1-9.2c-39.3 0-74.1 18.9-96 48H352c0 17.7-14.3 32-32 32h-8.2c-1.7 4.8-3.7 9.5-5.8 14.1l5.8 5.8c12.5 12.5 12.5 32.8 0 45.3l-22.6 22.6c-12.5 12.5-32.8 12.5-45.3 0l-5.8-5.8c-4.6 2.2-9.3 4.1-14.1 5.8V480c0 17.7-14.3 32-32 32H160c-17.7 0-32-14.3-32-32v-8.2c-4.8-1.7-9.5-3.7-14.1-5.8l-5.8 5.8c-12.5 12.5-32.8 12.5-45.3 0L40.2 449.1c-12.5-12.5-12.5-32.8 0-45.3l5.8-5.8c-2.2-4.6-4.1-9.3-5.8-14.1H32c-17.7 0-32-14.3-32-32V320c0-17.7 14.3-32 32-32h8.2c1.7-4.8 3.7-9.5 5.8-14.1l-5.8-5.8c-12.5-12.5-12.5-32.8 0-45.3l22.6-22.6c9-9 21.9-11.5 33.1-7.6V192 160 64zm170.3 0H160v96h32H304.7L266.3 64zM176 256a80 80 0 1 0 0 160 80 80 0 1 0 0-160zM528 448a24 24 0 1 0 0-48 24 24 0 1 0 0 48zm0 64c-48.6 0-88-39.4-88-88c0-29.8 14.8-56.1 37.4-72c14.3-10.1 31.8-16 50.6-16c2.7 0 5.3 .1 7.9 .3c44.9 4 80.1 41.7 80.1 87.7c0 48.6-39.4 88-88 88z" /></svg>
            <h3>Apoyamos el campo de Colombia</h3>
          </div>


          <div className="container--icons">
            <svg xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="50 0 550 512">{/*Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. */}<path d="M320 488c0 9.5-5.6 18.1-14.2 21.9s-18.8 2.3-25.8-4.1l-80-72c-5.1-4.6-7.9-11-7.9-17.8s2.9-13.3 7.9-17.8l80-72c7-6.3 17.2-7.9 25.8-4.1s14.2 12.4 14.2 21.9v40h16c35.3 0 64-28.7 64-64V153.3C371.7 141 352 112.8 352 80c0-44.2 35.8-80 80-80s80 35.8 80 80c0 32.8-19.7 61-48 73.3V320c0 70.7-57.3 128-128 128H320v40zM456 80a24 24 0 1 0 -48 0 24 24 0 1 0 48 0zM192 24c0-9.5 5.6-18.1 14.2-21.9s18.8-2.3 25.8 4.1l80 72c5.1 4.6 7.9 11 7.9 17.8s-2.9 13.3-7.9 17.8l-80 72c-7 6.3-17.2 7.9-25.8 4.1s-14.2-12.4-14.2-21.9V128H176c-35.3 0-64 28.7-64 64V358.7c28.3 12.3 48 40.5 48 73.3c0 44.2-35.8 80-80 80s-80-35.8-80-80c0-32.8 19.7-61 48-73.3V192c0-70.7 57.3-128 128-128h16V24zM56 432a24 24 0 1 0 48 0 24 24 0 1 0 -48 0z" /></svg>
            <h3>Productos versátiles</h3>
          </div>
        </div>

      </div>

      <div className="container--left">
        <div className="container--blog-inicio">
          <a href="/blog"><h2>Conoce nuestro Blog</h2></a>
          <div className="card--acai-bowl-inicio">
            <h3>Preparación fácil de bowl saludable con pulpa de açaí en casa en pocos pasos</h3>

            <a href="/blog"> <div className="image" ></div></a>
            <p>Los bowls con pulpa de açaí se han convertido en un desayuno o merienda popular debido a su sabor delicioso
              y los múltiples beneficios que ofrece la fruta açaí. ¡Y lo mejor de todo es que son muy fáciles de preparar!
              Sigue estos sencillos pasos para crear tu propio bowl con pulpa de açaí en casa. Ingredientes: 1 paquete de pulpa de açaí (100 gramos). 1/2 taza de leche...</p>
            <a href="/blog"><button className="button--blog"> Ver Blog</button></a>
          </div>

        </div>

      </div>

      <div>

        <a href="https://wa.me/573157697316?text=Hola%2C%20quisiera%20comprar%20pulpas" className="btn-wsp" target="_blank" rel="noreferrer">
          <i className="fa fa-whatsapp icono" />
          <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi_bi_whatsapp" viewBox="0 0 16 16">
            <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
          </svg>
        </a>
      </div>
    </section>
  );
}

export default Inicio;







