import "./styles/normalize.css";

import "./App.css";
//import Topbar from "./components/Topbar";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Inicio from "./pages/Inicio";
import Productos from "./pages/Productos";
import SobreNosotros from "./pages/SobreNosotros";
import Encuentranos from "./pages/Encuentranos"
import Contactanos from "./pages/Contactanos"
import PedidosHidden from "./pages/PedidosHidden";
import Blog from "./pages/Blog";
import PostreMaracuya from "./pages/PostreMaracuya";
import BeneficiosAcai  from "./pages/BeneficiosAcai";
import BowlAcai from "./pages/BowlAcai";
import Gracias from "./pages/Gracias";
import Error404 from "./pages/Error404";


import { BrowserRouter as Router, Route, Routes } from "react-router-dom";



function App() {
  return (
   
      <main className="App">
        <Router>
        <Navbar />
          {/*<Topbar />*/}
          <Routes>
            <Route path='/' exact element={<Inicio />} />
            <Route path="/productos" exact element={<Productos />} />
            <Route path="/sobrenosotros" exact element={<SobreNosotros />} />
            <Route path="/encuentranos" exact element={<Encuentranos />} />
            <Route path="/contactanos" exact element={<Contactanos />} />
            <Route path="/pedidos" exact element={<PedidosHidden />} />
            <Route path="/gracias" exact element={<Gracias />} />
            <Route path="/blog" exact element={<Blog />} />
              <Route path="blog/postre-maracuya" exact element={<PostreMaracuya />} />
              <Route path="blog/beneficios-acai" exact element={<BeneficiosAcai />} />
              <Route path="blog/bowl-acai" exact element={<BowlAcai />} />
             {/* <Route path="blog/jugos" exact element={<Jugos />} />*/}
             <Route path="*" exact element={< Error404 />} />
          </Routes>
          <Footer />
        </Router>
      </main>

   
  );
}

export default App;
