import React, { useState } from "react";
import Logo from "../assets/assetslagranja/Logo_Pulpas-removebg-preview.png";
import { Link } from "react-router-dom";
import ReorderIcon from '@mui/icons-material/Reorder'; 

import "../styles/normalize.css";
import "../styles/Navbar.css";


function Navbar() {
  const [openLinks, setOpenLinks] = useState(false);
  
  const toggleNavbar = () => {
    setOpenLinks(!openLinks);
  };
  return (

      <nav className="navbar">
      <div className="container--left-side" id={openLinks ? "open" : "close"}>
      <a className="link--home" href="/"><img src={Logo} /></a>
        <div className="container--hidden-links">
          <Link to="/"> Inicio </Link>
          <Link to="/productos"> Productos </Link>
          <Link to="/sobreNosotros"> Sobre nosotros </Link>
          <Link to="/encuentranos"> Encuéntranos </Link>
          <Link to="/contactanos"> Contáctanos </Link>
          <Link to="/blog"> Blog </Link>
        </div>
      </div>
      <div className="container--right-side">
        <Link to="/"> Inicio </Link>
        <Link to="/productos"> Productos </Link>
        <Link id="link--SN" to="/sobreNosotros">Sobre nosotros</Link>
        <Link to="/encuentranos"> Encuéntranos </Link>
        <Link to="/contactanos"> Contáctanos </Link>
        <Link to="/blog"> Blog </Link>
        <button onClick={toggleNavbar}>
          <ReorderIcon />
        </button>
      </div>
    </nav>

  );
}

export default Navbar;