import React from "react";
import slide1_mob from '../assets/assetslagranja/Farm-mobile.webp'

import "../styles/normalize.css";
import "../styles/SobreNosotros.css";


function sobreNosotros() {
  return (
    <section className="about">
      <div
        className="container--Banner-page-SN"
        style={{ backgroundImage: `url(${slide1_mob})` }}
      ><div className="container--centered-SN"><h1>Sobre nosotros</h1></div></div>
      <div className="container--aboutBottom">

        <h3>DEL CAMPO A TU MESA, FABRICANTES DE PULPA DE FRUTA COLOMBIANA</h3>
        <h3 className="container__header--italic">¡TAN NATURAL COMO COMERSE LA FRUTA!</h3>
        <table>
          <tbody>
          <tr className="table__first-row">

            <td className="first-row__first-cell">
              <div className="container--expertos">
                <h4>SOMOS EXPERTOS</h4>
                <p>
                  En Pulpas la Granja somos productores y distribuidores de pulpas de fruta congelada, pulpas de combinaciones de frutas y pulpas saludables. Nuestro objetivo es resaltar la riqueza del campo colombiano, transformando las frutas que cultivan nuestros campesinos en productos funcionales, saludables y exquisitos.
                  <br></br><br></br>Nos apasiona lo natural, poder resaltar los sabores de la tierra colombiana y brindar bienestar a nuestros clientes nos hace vibrar. Llegar a la mesa de cada hogar con una bebida de la mejor calidad es nuestro compromiso y ofrecer un servicio ágil, eficiente y amigable es nuestro mayor pilar.
                </p>
              </div>
              </td>
              <td  className="first-row__second-cell">
              <div className="container--expertos-img" >
              </div>
              </td>

          </tr>
          <tr className="table__second-row">


            <td className="second-row__first-cell">
              <div className="container--atributos-img">

              </div>
              </td>
              <td  className="second-row__second-cell">
              <div className="container--atributos">

                <h4>FUNCIONALES</h4>
                <p>
                  Mantén tus pulpas congeladas y prepara bebidas en cuestión de segundos
                </p>
                <h4>100% NATURALES</h4>
                <p>
                  Pulpas de fruta tradicionales y exóticas 100% naturales, sin azúcar añadida, sin conservantes ni colorantes artificiales.
                </p>
                <h4>VERSATILES</h4>
                <p>
                  Perfectas para preparar jugos, sodas saborizadas, mermeladas, bowls saludables, salsas, malteadas, helados y muchas más preparaciones de cocina.
                </p>
              </div>
              </td>

          </tr>
          </tbody>
        </table>

      </div>

      <div>

        <a href="https://wa.me/573157697316?text=Hola%2C%20quisiera%20comprar%20pulpas" className="btn-wsp" target="_blank">
          <i className="fa fa-whatsapp icono" />
          <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi_bi_whatsapp" viewBox="0 0 16 16">
            <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
          </svg>
        </a>
      </div>

    </section>
  );
}

export default sobreNosotros;