import React from "react";

function ProductItem({ image, name, price }) {
  return (
    <div className="container--product-item">
      <div style={{ backgroundImage: `url(${image})` }}> </div>
      <h1> {name} </h1>
      <p> {price} </p>
      <a className="link--producto" href="/pedidos"> 
        <button className="button--producto">
          Comprar 
        </button>
      </a> 
    </div>
  );
}

export default ProductItem;