import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";

import "../styles/normalize.css";
import "../styles/Footer.css";
import {Link } from "react-router-dom";

function Footer() {
    return (
      <footer className="footer">
        <div className="footer__container--left-side">
            <Link to="https://www.instagram.com/PulpasLaGranja/" target="_blank"><InstagramIcon /></Link>
             <p className="container__p--sociedad">  LLO & P Group<br></br>
                                                Pulpas Colombianas de Exportación  S.A.S.
             </p>

             <p className="container__p--horarios"> Horario de atención pedidos. L - D 7:00 AM -- 7:00 PM</p>
          </div>
          <div className="footer__container--center">
            <p className="container__p--copyright"> &copy; 2023 JRMZdev</p>
          </div>
          <div className="footer__container--right-side">
            
            <a href="https://www.google.com/maps/dir//Tv.+33+Sur+%2331+B+45+Las+Flores+Envigado,+Antioquia/@6.1743204,-75.580045,21z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8e4682572cadb187:0x5859d867c991ac52!2m2!1d-75.580045!2d6.1743204?entry=ttu"  target="_blank">
            <p className="container__p--direccion"> Transversal 33 Sur #31 B 45 </p>
            <p className="container__p--ciudad"> Envigado, Antioquia, Colombia</p>
            </a>
          </div>
      </footer>
    );
  }
  
  export default Footer;