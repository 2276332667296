import React from 'react'
import pineApple from '../assets/assetslagranja/pineapple_landscape.jpg'
import Logo from '../assets/assetslagranja/Logo_Pulpas-removebg-preview.png'

import "../styles/normalize.css";
import "../styles/PedidosHidden.css"

function PedidosHidden() {
  return (
  <section className='Pedidos'>
      <div
          className="container--Banner-page-pedidos"
          style={{ backgroundImage: `url(${pineApple})`}}
        >
          <div className='container--pedidos'>
                <div
                    className="container--logo-pedidos"
                    style={{ backgroundImage: `url(${Logo})`}}
                    >
                </div>

                <div className="container--button-section" id="botones-pedido">
                <a href="https://wa.me/573157697316?text=Hola%2C%20quisiera%20comprar%20pulpas" className="btn-wsp" target="_blank"><button className ="container__button--pide-wpp" id="boton-wpp">Pide por Whatsapp</button></a>
                  {/*<button className ="pide_form_button"> Pide en linea vía Formulario </button>*/}
              <a href="/productos"><button className ="container__button--volver" id="boton-volver"> Volver</button></a>
                </div>
           </div>
       </div>
   </section>


  )
}

export default PedidosHidden